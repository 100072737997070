import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchMembershipPlans, changePlan, conditionModel } from "../../actions";
import * as constand from "../../constant";
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player';
import Modal from "react-responsive-modal";
import { Cookies } from "react-cookie-consent";
import { commonService } from "../../_services";
import ConditionalModalComponent from "../../_components/Common/ConditionalModalComponent";

let valid = require("card-validator");

class HowitworksComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            membershipList: [],
            user_data: [],
            hashTag: this.props.location.hash,
            trialLoading: false,
            videoUrl: 'https://youtu.be/h826zEJLeIk',
            payment_data: {},
            ismodel_open: false,
            condition: null,
            url: ''
        };
        this.closeModel = this.closeModel.bind(this);
        this.handlePaymentChange = this.handlePaymentChange.bind(this);
        this.updatePaymentData = this.updatePaymentData.bind(this);
        this.numberOnly = this.numberOnly.bind(this);
        this.expiremonthValid = this.expiremonthValid.bind(this);
        this.expireyearValid = this.expireyearValid.bind(this);

    }
    componentDidMount() {
        var countryCode = this.props.user_data && this.props.user_data.Country
            && Object.keys(this.props.user_data.Country).length > 0 ?
            this.props.user_data.Country.countryCode : ''
        this.fetchPlans(countryCode);
        this.scrollToBottom();
    }

    componentWillReceiveProps(props) {
        this.setState({ membershipList: props.membershipPlans.length ? props.membershipPlans[0] : {}, user_data: props.user_data });
    }
    scrollToBottom = () => {
        if (this.props.location.hash === '#pricing') {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        } else {
            window.scrollTo(0, 0)
        }
    }
    componentDidUpdate() {
        this.scrollToBottom();
    }
    //startTrail(planData) {
    startTrail(plan, type) {
        //submission of both promo and membership plan
        var planData = {
            planId: plan.id,
            type: type
        };
        if (type === 'change_plan') {
            const paymentData = this.state.payment_data;
            var cur_month = new Date().getMonth();
            let cur_year = new Date().getFullYear();
            if (new Date(paymentData.card_exp_year + '-' + paymentData.card_exp_month + '-01') < new Date(cur_year + '-' + cur_month + '-01')) {
                this.setState({ payment_data: { ...this.state.payment_data, invalid_expiration: true } });
                return;
            }

            planData.card_name = paymentData.card_name;
            planData.number = paymentData.card_number;
            planData.exp_month = paymentData.card_exp_month;
            planData.exp_year = paymentData.card_exp_year;
            planData.cvc = paymentData.card_security_code;
            planData.card_zipcode = paymentData.card_zipcode;
        }
        //var planData = { planId: plan.id, type: "change_plan" };
        this.setState({ trialLoading: true });
        this.closeModel();

        this.props.changePlan(planData).then(
            response => {
                this.setState({ trialLoading: false });
                toast.success(response.message);

            },
            error => {
                this.setState({ trialLoading: false });
                toast.error(error);
            }
        );
    }
    /**
   *  fetch Plans
   */
    fetchPlans(code) {
        this.props.fetchMembershipPlans(code).then(
            response => {
                if (response) {
                    this.setState({
                        membershipList: response.plans.length ? response.plans[0] : {}
                    });
                }
            },
            error => {
                toast.error(error);
            }
        );
    }
    submitPayment(plandata) {
        var newValue = {};
        newValue.submitted = true;
        this.setState(
            { payment_data: { ...this.state.payment_data, ...newValue } },
            function () {
                this.progressPayment(plandata);
            }
        );
    }
    progressPayment(plandata) {
        if (
            this.state.payment_data.card_name &&
            this.state.payment_data.card_number &&
            valid.number(this.state.payment_data.card_number).isValid &&
            this.state.payment_data.card_exp_month &&
            this.state.payment_data.card_exp_month <= 12 &&
            this.state.payment_data.card_exp_year &&
            this.state.payment_data.card_exp_year >= new Date().getFullYear() &&
            this.state.payment_data.card_security_code &&
            this.state.payment_data.card_security_code.length ===
            valid.number(this.state.payment_data.card_number).card.code.size &&
            this.state.payment_data.card_zipcode
        ) {
            this.startTrail(plandata, 'change_plan');
        } else {
            return false;
        }
    }
    paymentPage(planData) {
        const membership_plan = planData; //this.state.planData;
        return (
            <div className="step14">
                <div className="row justify-content-center align-items-center">
                    <div className="input_section card-details--page col-md-9 m-t-10">
                        <div className="form-group">
                            <div className="row">
                                <div className="form-group col-md-6 float-left">
                                    <label htmlFor="card_name" className="text-label">
                                        Name on Card
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control input-control"
                                            name="card_name"
                                            placeholder=""
                                            onChange={this.handlePaymentChange}
                                            value={this.state.payment_data.card_name}
                                            required
                                        />
                                    </div>
                                    {this.state.payment_data.submitted &&
                                        !this.state.payment_data.card_name && (
                                            <p className="help-block text-danger">
                                                Name on card required
                                            </p>
                                        )}
                                </div>
                                <div className="form-group col-md-6 float-left">
                                    <label htmlFor="card_number" className="text-label">
                                        Card number
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control input-control"
                                            name="card_number"
                                            placeholder=""
                                            onChange={this.numberOnly}
                                            value={this.state.payment_data.card_number}
                                        />
                                    </div>
                                    {this.state.payment_data.submitted &&
                                        !this.state.payment_data.card_number && (
                                            <p className="help-block  text-danger">
                                                Card number required
                                            </p>
                                        )}
                                    {this.state.payment_data.submitted &&
                                        this.state.payment_data.card_number &&
                                        !valid.number(this.state.payment_data.card_number)
                                            .isValid && (
                                            <p className="help-block  text-danger">
                                                Invalid card number
                                            </p>
                                        )}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-lg-5 expiration-date">
                                    <div className="form-group">
                                        <label className="text-label">
                                            <span className="hidden-xs">Expiration</span>{" "}
                                        </label>
                                        <div className="form-inline">
                                            <div className="col-md-6 p-l-0 exp-date">
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control input-control"
                                                        name="card_exp_month"
                                                        placeholder="MM"
                                                        onChange={this.expiremonthValid}
                                                        value={this.state.payment_data.card_exp_month}
                                                        required
                                                    />
                                                </div>
                                                {this.state.payment_data.submitted &&
                                                    !this.state.payment_data.card_exp_month && (
                                                        <p className="help-block  text-danger">
                                                            Month required
                                                        </p>
                                                    )}
                                                {this.state.payment_data.submitted &&
                                                    this.state.payment_data.card_exp_month > 12 && (
                                                        <p className="help-block  text-danger">
                                                            Invalid month
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-6 p-0">
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control input-control"
                                                        name="card_exp_year"
                                                        placeholder="YYYY"
                                                        onChange={this.expireyearValid}
                                                        value={this.state.payment_data.card_exp_year}
                                                        required
                                                    />
                                                </div>
                                                {this.state.payment_data.submitted &&
                                                    !this.state.payment_data.card_exp_year && (
                                                        <p className="help-block  text-danger">
                                                            Expire year required
                                                        </p>
                                                    )}
                                                {this.state.payment_data.submitted &&
                                                    this.state.payment_data.card_exp_year &&
                                                    this.state.payment_data.card_exp_year <
                                                    new Date().getFullYear() && (
                                                        <p className="help-block  text-danger">
                                                            Invalid expire year
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="card_security_code" className="text-label">
                                            Security Code
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="card_security_code"
                                                placeholder=""
                                                onChange={this.handlePaymentChange}
                                                value={this.state.payment_data.card_security_code}
                                                required
                                            />
                                        </div>
                                        {this.state.payment_data.submitted &&
                                            !this.state.payment_data.card_security_code && (
                                                <p className="help-block  text-danger">
                                                    Security code required
                                                </p>
                                            )}
                                        {this.state.payment_data.submitted &&
                                            (this.state.payment_data.card_security_code &&
                                                valid.number(this.state.payment_data.card_number)
                                                    .card) &&
                                            this.state.payment_data.card_security_code.length !==
                                            valid.number(this.state.payment_data.card_number).card
                                                .code.size && (
                                                <p className="help-block  text-danger">
                                                    Invalid security code
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="card_zipcode" className="text-label">
                                            ZIP/Postal code
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control input-control"
                                                name="card_zipcode"
                                                placeholder=""
                                                onChange={this.handlePaymentChange}
                                                value={this.state.payment_data.card_zipcode}
                                                required
                                            />
                                        </div>
                                        {this.state.payment_data.submitted &&
                                            !this.state.payment_data.card_zipcode && (
                                                <p className="help-block  text-danger">
                                                    ZIP/Postal code required
                                                </p>
                                            )}
                                        {this.state.payment_data.submitted &&
                                            this.state.payment_data.card_zipcode && this.state.payment_data.card_zipcode.length > 6 && (
                                                <p className="help-block  text-danger">
                                                    Invalid ZIP/Postal code
                                                </p>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="small-txt font-book text-center">
                            Due today and monthly ongoing:{" "}
                            <span className="orangefont p-l-10">
                                {membership_plan.currency} {membership_plan.amount}
                            </span>
                        </p>
                        {(this.state.payment_data.invalid_expiration) &&
                            <p className="small-txt font-book text-center text-danger m-t-10">Invalid Expiration.</p>}
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="input_section col-md-6 m-t-10">
                        <div id="register-link" className=" w-100 m-t-20 m-b-20 float-left">
                            <a
                                href="javascript:void(0)"
                                onClick={() => this.submitPayment(planData)}
                                className="bluebtn float-left w-100 font-16 font-medium text-center"
                            >
                                Become a Member
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    getPopup() {
        this.setState({ ismodel_open: true })
    }
    pricingPopup(item) {

        return (
            <Modal
                classNames={{
                    modal: "pricing-popup m-t-50"
                }}
                open={this.state.ismodel_open}
                onClose={() => { }}
                center
            >
                <div className="modal-dialog modal-width--custom grid-four-pricing" role="document">
                    <div className="modal-content">
                        <div className="modal-header header-styling  border-0">
                            <h5
                                className="modal-title text-center col-md-11 p-0 font-semibold"
                                id="exampleModalLabel font-medium"
                            >
                                {"Become a member"}

                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true" onClick={this.closeModel}>
                                    &times;
                                </span>
                            </button>
                        </div>
                        <div className="modal-body body-padding--value pb-0">
                            {this.paymentPage(item)}
                        </div>
                    </div>
                </div>
            </Modal>);
    }
    updatePaymentData(name, value) {
        var newValue = {};
        newValue[name] = value;
        this.setState({
            payment_data: { ...this.state.payment_data, ...newValue }
        });
    }

    handlePaymentChange(e) {
        const { name, value } = e.target;
        this.updatePaymentData(name, value);
    }
    numberOnly(e) {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex
        const { name, value } = e.target;
        if (value === "" || re.test(value)) {
            this.updatePaymentData(name, value);
        }
    }
    expiremonthValid(e) {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex
        const { name, value } = e.target;
        if ((value === "" || re.test(value)) && value.length <= 2) {
            this.updatePaymentData(name, value);
        }
    }

    expireyearValid(e) {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        const { name, value } = e.target;
        if ((value === "" || re.test(value)) && value.length <= 4) {
            this.updatePaymentData(name, value);
        }
    }
    closeModel() {
        //for close the login model
        this.setState({ ismodel_open: false });
    }

    closeConditionModal = () => {
        this.props.conditionModel(false, true)
    }

    getConditionPopupPage = (conditionVal) => {
        console.log("conditionVal", conditionVal)
        this.props.conditionModel(false, true);
        Cookies.set("condition", conditionVal);
        var conditionName = Cookies.get('condition') ? Cookies.get('condition') : this.props.init_condition;
        var condition = commonService.replaceChar(conditionName, false);
        var pageUrl = this.state.url + condition;
        console.log("pageUrl", pageUrl)
        this.props.history.push(pageUrl);
        this.setState({ condition: condition })
    }

    checkIsConditionSet = (url) => {
        this.setState({ url: url })
        var conditionName = Cookies.get('condition') ? Cookies.get('condition') : this.props.init_condition;
        if (!conditionName) {
            this.props.conditionModel(true, true);
        } else {
            var condition = commonService.replaceChar(conditionName, false);
            var pageUrl = url + condition;
            this.props.history.push(pageUrl);
            this.setState({ condition: condition })
        }
    }
    render() {

        return (
            <section className={((typeof Cookies.get('closeBanner') == 'undefined' || Cookies.get('closeBanner') == 'false') && !this.props.close_banner) || ((typeof Cookies.get('closeBannerKD') == 'undefined' || Cookies.get('closeBannerKD') == 'false') && !this.props.close_banner_kd) ? 'howitworks-page' : 'howitworks-page m-t-80'} >
                <Helmet>
                    <title>{constand.ABOUT_HOW_TITLE}{constand.BEAM}</title>
                    <meta property="og:title" content={constand.ABOUT_HOW_TITLE + constand.BEAM} />
                    <meta property="og:description" content={constand.ABOUT_HOW_DESC} />
                    <meta property="og:image" content={constand.ABOUT_HOW_PAGE_IMAGE} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="og:site_name" content="Beam" />
                    <meta name="twitter:image:alt" content={constand.ABOUT_HOW_PAGE_IMAGE_ALT} />
                </Helmet>
                <div className="section-1">
                    <div className="container">
                        <div className="section-header text-center p-t-30 p-b-30">

                            <div className="col-md-12 ">
                                <h5 className="font-37 text-center white-txt font-medium m-t-20 m-b-30">How it works</h5>
                                <p className="w-80 mr-auto ml-auto  font-21 font-medium">
                                    Access on-demand videos, live classes and progressive programmes led by specialist physiotherapists and exercise instructors who are either trained in or live with your kidney disease
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-2 p-t-80">
                    <div className="container-fluid mx-auto w-75">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 text-center p-b-30">
                                <ReactPlayer url={this.state.videoUrl} controls={true} width='100%' height='100%' style={{
                                    width: '100%',
                                    height: '100%'
                                }} />

                                {/* <figure className="text-left"><img src="/images/blog_img_044.png" className="img-fluid" alt="" /></figure> */}
                            </div>

                            <div className="col-12 col-md-6 col-lg-6 text-left d-flex align-items-center">
                                <div className="card cardnew border-0">
                                    <h4 className="m-b-20 darkblue-text font-semibold font-normal font-24 card-block">Sign up</h4>
                                    <p className="font-16 black-txt font-qregular">
                                        Our step-by-step sign up process is super simple.</p>
                                    <p className="font-16 black-txt font-qregular">
                                        We just require you to answer a few basic questions to help us get to know you, like what name you'd like us to call you by.
                                    </p>
                                    <p className="font-16 black-txt font-qregular">
                                        Some details that you input will also help to establish whether you are entitled to special access to the platform or what type of membership will suit you.
                                    </p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12 mx-auto text-center">
                                            <Link class="btn btn-beam-blue btn-signup col-md-12 col-8 p-t-10 p-b-10" to={'/register'}>Sign up now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-3 bg-white p-t-80">
                    <div className="container-fluid m-auto w-75">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center  col-md-7 col-lg-7 text-left  p-b-30">
                                <div className="card cardnew border-0">
                                    <div className="card-block">
                                        <h4 className="clearfix  darkblue-text font-semibold font-normal font-24 float-left w-100">Find on-demand sessions</h4>
                                    </div>
                                    <div className="font-16 font-qregular black-txt m-t-30">
                                        <div>
                                            Take a class tailored to your needs whenever you want to, by searching for something that takes your fancy from our on-demand library of classes.
                                        </div>
                                        <div>
                                            Found a session that suits? Great!
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-8 col-md-12 col-sm-12 mx-auto text-center">
                                                <Link class="btn btn-yellow btn-on-demand col-md-12 col-8 p-t-10 p-b-10" onClick={() => this.checkIsConditionSet('/on-demand/')} to={"#"}>Go to on-demand classes</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 text-center">
                                <figure><img src={constand.WEB_IMAGES + "Asset-4.png"} className="img-fluid assetimg" alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-4">
                    <div className="p-t-70 p-b-70">
                        <div className="container">
                            <h4 className="schedule-heading text-center font-normal font-24 font-semibold ">It might also help to know that you can:</h4>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-t-10 p-b-10">
                                    <div className="box-part text-center">
                                        {/* <i className="fa fa-calendar-o fa-3x" aria-hidden="true"></i> */}
                                        <img src={constand.WEB_IMAGES + "schedule.png"} className="img-resposive" alt="" />
                                        <div className="title">
                                            <h4 className="font-semibold font-16">Schedule a session</h4>
                                        </div>
                                        <div className="text">
                                            <span className="font-qregular black-txt font-16">
                                                Add a class to your Kidney Beam schedule to set a positive intention to
                                                complete the session, and receive reminders from us to help you
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-t-10 p-b-10">
                                    <div className="box-part text-center">
                                        <i className="fa fa-bookmark fa-3x" aria-hidden="true"></i>
                                        <div className="title">
                                            <h4 className="font-semibold font-16">Save a session</h4>
                                        </div>
                                        <div className="text">
                                            <span className="font-qregular black-txt font-16">
                                                Found a session that you love? Been recommended a session by your physio? Hit the save button to store it for later.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 p-t-10 p-b-10">
                                    <div className="box-part text-center">
                                        <i className="fa fa-share fa-3x" aria-hidden="true"></i>
                                        <div className="title">
                                            <h4 className="font-semibold font-16">Share a session</h4>
                                        </div>
                                        <div className="text">
                                            <span className="font-qregular black-txt font-16">
                                                Sharing is caring! The bigger our community, the more healthy, happy people there are to support you. Love a class? Tell a friend!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-5 p-t-80">
                    <div className="container-fluid mx-auto w-75">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 text-center p-b-30">
                                <figure className="text-center"><img src={constand.WEB_IMAGES + "how_blog1.png"} className="img-fluid w-75 liveclassimg" alt="" /></figure>
                            </div>

                            <div className="col-12 col-md-6 col-lg-6 text-left d-flex align-items-center">
                                <div className="card cardnew border-0">
                                    <h4 className="m-b-20 font-semibold darkblue-text font-normal font-24 card-block">Take a live class</h4>
                                    <p className="font-16 black-txt font-qregular">
                                        Live classes allow you to experience the feel-good energy of a live class wherever you are. Just like our on-demand sessions, live classes are run by specialist physiotherapists and instructors who are trained in or live with your medical condition. We want you to know that they understand you and they've got your back, every minute of every class.
                                    </p>
                                    <p className="font-16 black-txt font-qregular">
                                        Get live encouragement during the sessions, connect with your instructor and class-mates pre and post-session and feel supported in every step by people who deeply understand your kidney disease.
                                    </p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12 mx-auto text-center">
                                            <Link class="btn btn-orange btn-signup col-md-12 col-8 p-t-10 p-b-10" onClick={() => this.checkIsConditionSet('/liveClasses/')} to={"#"}>Go to live classes</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-9 p-t-80">
                    <div className="container-fluid mx-auto w-75">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 text-left d-flex align-items-center">
                                <div className="card cardnew border-0">
                                    <h4 className="m-b-20 font-semibold darkblue-text font-normal font-24 card-block">Complete a Programme</h4>
                                    <p className="font-16 black-txt font-qregular">
                                        Programmes are a great place for you to start Beaming. Created specifically for your health needs, Kidney Beam programmes will help you to build your strength and fitness one video at a time. Once you have completed a Start Beaming programme, celebrate! You can then choose to do it again, browse our other content, or take a Spotlight Programme. Spotlight programmes will introduce you to new movement disciplines or help to address your more complex health needs.
                                    </p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12 mx-auto text-center">
                                            <Link class="btn btn-voliet btn-signup col-md-12 col-8 p-t-10 p-b-10" onClick={() => this.checkIsConditionSet('/programmes/')} to={"#"}>Go to programmes</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 text-center program-section p-b-30">
                                <figure className="text-center"><img src={constand.WEB_IMAGES + "Big_hair_7_RGB.svg"} className="img-fluid w-95 liveclassimg" alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-6 p-t-80 bg-white">
                    <div className="container-fluid mx-auto w-75">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-5 text-center p-b-30">
                                <figure><img src={constand.WEB_IMAGES + "blog_img_04.png"} className="img-fluid" alt="" /></figure>
                            </div>
                            <div className="col-12 d-flex align-items-center  col-md-7 col-lg-7 text-left left-padding">
                                <div className="card cardnew border-0">
                                    <h4 className="m-b-20 font-semibold darkblue-text font-normal font-24 card-block">Join the Kidney Beam community</h4>
                                    <p className="font-16 black-txt font-qregular m-t-10">
                                        Whether you make friends in a Kidney Beam group, talk with us on Twitter or say hello to someone in a live class, you'll love being a part of our feel-good-family.We're all here to lift each other up, so you get the support you need every step of the way.
                                    </p>
                                    <div className="social-links">
                                        <ul className="text-left">
                                            <li><a href="https://twitter.com/beamfeelgood" target="_blank">
                                                <i className="fa fa-twitter"></i>
                                            </a></li>
                                            <li><a href="https://www.facebook.com/feelgoodbeam" target="_blank">
                                                <i className="fa fa-facebook"></i>
                                            </a></li>
                                            <li><a href="https://www.instagram.com/beam_cf/" target="_blank">
                                                <i className="fa fa-instagram"></i>
                                            </a></li>
                                            <li><a href="https://www.linkedin.com/company/beamfeelgood" target="_blank">
                                                <i className="fa fa-linkedin"></i>
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-7 p-t-20" >
                    <div className="container-fluid mx-auto w-75">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 text-left d-flex align-items-center p-b-30">
                                <div className="card cardnew border-0">
                                    <h4 className="m-b-20 darkblue-text font-semibold font-normal font-24 card-block">Have a little read</h4>
                                    <p className="font-16 black-txt font-qregular">
                                        We know that advice on keeping active for your specific medical condition can be hard to find. Between generic advice that isn't quite specific enough for you and specific journal articles that can seem totally mind-boggling, it's tough.
                                    </p>
                                    <p className="font-16 black-txt font-qregular">
                                        We aim to bridge the gap with blog posts that tell you stuff that you need to know, but might struggle to find elsewhere.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 ordering text-center left-padding">
                                <figure className="text-left"><img src={constand.WEB_IMAGES + "live4.png"} className="img-fluid" alt="" /></figure>
                            </div>
                        </div>
                    </div>
                    <div ref={(el) => { this.messagesEnd = el; }}></div>
                </div>
                <div className="m-t-80 p-b-30" >
                    <div className="container-fluid mx-auto w-75">
                        <h4 className="red-text text-center font-normal font-24 font-semibold m-b-20">Still got questions?</h4>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 mx-auto text-center">
                                <Link class="btn btn-purple btn-faq-howit col-md-12 col-8 p-t-10 p-b-10" to={'/faq'}>Read our FAQs</Link>
                            </div>
                        </div>
                        {/* <p className="text-center font-16 black-txt font-qregular m-t-40">
                            We are passionate about making Beam free for everyone and are working hard to achieve this.  To see if you are eligble for a free membership for the health condition(s) you are interested in please sign up and through the details you provide we will check your eligibility.  Or if you have a promo or access code this can be entered during sign up or alternatively on your membership page once signed up.  If you are not yet eligble then all is not lost - you can sign up for a 2 week free trial with no obligation to continue to a monthly membership afterwards.  Should you want to continue you can do for {(constand.Currency_Symbol[this.state.membershipList.currency]) || this.state.membershipList.currency} {this.state.membershipList.amount} per month and cancel anytime.</p>
                        <p className="text-center font-16 black-txt font-qregular m-b-50">
                            Beam offers exercise, education and support for lots of different health conditions and stages of life where you might need more specialist support.  You will need a different membership for each condition you are wanting access to.
                        </p>
                        <div className="row pricing" id="pricing" ref="theDiv" tabIndex={0}>
                            <div className="col-lg-6 col-md-6 col-sm-12 m-b-10">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body height-fix text-center">
                                        <h5 className="card-title font-bold font-30 ash-txt text-center">Free membership</h5>
                                        <figure className="text-center"><img src={
                                            constand.WEB_IMAGES + "member_ship/membership_1.png"
                                        } className="img-fluid img-fluid w-30" alt="" /></figure>
                                        <p className="font-qmedium ash-txt font-30">Hooray - Beam is free in some regions for some conditions!</p>

                                        <p className="font-16 black-txt text-center font-qmedium">If you’ve been given an access or promo code then you can enter this on sign up or on your membership page. Otherwise, your eligiblity for free access via one of our sponsors will be automatically determined on sign up.</p>

                                        {!this.props.is_auth &&
                                            <Link to="/register" className="btn font-bold font-16 btn-beam-blue m-t-20 m-b-20">Sign up now</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 m-b-10">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body height-fix text-center">
                                        <h5 className="card-title font-bold font-30 ash-txt text-center">Paid membership</h5>
                                        <figure className="text-center"><img src={
                                            constand.WEB_IMAGES + "member_ship/membership_2.png"
                                        } className="img-fluid img-fluid w-30" alt="" /></figure>
                                        <p className="font-qmedium ash-txt font-30">2 week free trial
                                            followed by {(constand.Currency_Symbol[this.state.membershipList.currency]) || this.state.membershipList.currency}{this.state.membershipList.amount} per month</p>

                                        <p className="font-16 black-txt text-center font-qmedium">(no credit card required to start the trial, no obligation to sign up after the trial)</p>

                                        {!this.props.is_auth &&
                                            <Link to="/register" className="btn font-bold font-16 btn-beam-blue m-t-60 m-b-20">Sign up now</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.membershipList.length === 0 && (
                            <div className="font-16 font-qmedium black-txt col-md-12 text-center m-t-40 m-b-20">
                                No memberships found !
                            </div>
                        )} */}

                    </div>
                </div>
                <>
                    <ConditionalModalComponent
                        isModalOpen={this.props.is_condition_model_open}
                        getConditionPopupPage={this.getConditionPopupPage}
                        closeConditionModal={this.closeConditionModal}
                        from="howItWorks"
                    />
                </>
            </section>
        );
    }
}
const mapStateToProps = state => {
    return {
        ip_data: state.accountinfo.ip_data,
        user_data: state.header.logged_userData,
        membershipPlans: state.register.membershipPlan,
        is_auth: state.auth.is_auth,
        close_banner: state.header.close_banner,
        close_banner_kd: state.header.close_banner_kd,
        init_condition: state.auth.initial_condition,
        is_condition_model_open: state.header.is_condition_model_open_by_howitworks
    };
};
const mapDispatchToProps = {
    fetchMembershipPlans,
    changePlan,
    conditionModel
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HowitworksComponent);
